var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-flex mobi d-none d-lg-none d-xl-block search"},[_c('b-row',[_c('b-col',{staticStyle:{"text-align":"left"},attrs:{"cols":"12","lg":"12"}},[_c('span',{staticStyle:{"display":"inline-flex"}},_vm._l((_vm.categories.slice(0, 10)),function(category,index){return _c('span',{key:index},[_c('b-nav-item-dropdown',{attrs:{"id":'dropdown-' + index}},[_c('template',{slot:"button-content"},[_c('span',{on:{"click":() => {
                    _vm.showSubCat(category.category, category.sub_cat);
                    _vm.bcategory({ cat_id: category.category.id });
                    _vm.$emit('activate:matching:category', null);
                    _vm.$emit('activateSuggestion', category.category.name);
                  }}},[_vm._v(" "+_vm._s(category.category.name)+" ")])]),_c('hr',{staticStyle:{"margin-top":"-10px","background-color":"red","height":"3px","width":"41%","float":"left"}}),_c('br'),_c('div',{ref:category.category.id,refInFor:true},[_c('div',{staticStyle:{"columns":"2"}},_vm._l((category.sub_cat.slice(0, 6)),function(subCat,subIndex){return _c('b-dropdown-item',{key:subIndex,staticClass:"ml-2",attrs:{"href":"#"},on:{"click":() => {
                      _vm.$store.commit('marketSearch/setSubCat', []);
                      _vm.$emit('update:keyword', {
                        keyword: subCat.name,
                        cat_id: subCat.cat_id
                      });
                      _vm.$emit('activate:matching:category', {
                        name: subCat.name
                      });
                      _vm.$emit('activateSuggestion', subCat.name);
                      _vm.bcategory({ cat_id: subCat.cat_id, id: subCat.id });
                    }}},[_c('img',{staticClass:"img-fluid picture logo-img",attrs:{"src":subCat.cat_image}}),_vm._v(" "+_vm._s(subCat.name)+" ")])}),1)])],2)],1)}),0),_c('span',{on:{"mouseover":function($event){return _vm.onOverMore()},"mouseleave":function($event){return _vm.onLeaveMore()}}},[_c('b-nav-item-dropdown',{ref:"more",attrs:{"id":"dropdown-1","text":"More"}},[_c('hr',{staticStyle:{"margin-top":"-10px","background-color":"red","height":"3px","width":"40%","float":"left"}}),_c('br'),_c('div',[_c('b-row',_vm._l((_vm.categories.slice(6)),function(category,index){return _c('b-col',{key:index,staticClass:"padding:50px",attrs:{"cols":"6"}},[_c('b-dropdown-item',{staticClass:"ml-1",on:{"click":() => {
                      _vm.$emit('onChangeCategoryName', category.category.name);
                      _vm.bcategory({ cat_id: category.category.id });
                      _vm.$emit('activate:matching:category', null);
                      _vm.$emit('activateSuggestion', category.category.name);
                      _vm.$emit('update:keyword', {
                        keyword: category.category.name,
                        cat_id: category.category.id
                      });
                    }}},[_vm._v(" "+_vm._s(category.category.name)+" ")])],1)}),1)],1)])],1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }